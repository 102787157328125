{
    "ports": [
        { "id": 46130, "portName": "LAGOS", "country": "NG", "latitude": 6.4, "longitude": 3.4 },
        { "id": 27650, "portName": "VAASA", "country": "FI", "latitude": 63.1, "longitude": 21.583333 },
        { "id": 35840, "portName": "PORT OF LE HAVRE", "country": "FR", "latitude": 49.483333, "longitude": 0.116667 },
        { "id": 45010, "portName": "AL LADHIQIYAH", "country": "SY", "latitude": 35.533333, "longitude": 35.766667 },
        { "id": 48175, "portName": "AL MUKHA", "country": "YE", "latitude": 13.316667, "longitude": 43.25 },
        { "id": 27570, "portName": "JAKOBSTAD", "country": "FI", "latitude": 63.683333, "longitude": 22.666667 },
        { "id": 28620, "portName": "VENTSPILS", "country": "LV", "latitude": 57.4, "longitude": 21.533333 },
        { "id": 31250, "portName": "ANTWERPEN", "country": "BE", "latitude": 51.216667, "longitude": 4.4 },
        { "id": 45730, "portName": "CEUTA", "country": "ES", "latitude": 35.9, "longitude": -5.316667 },
        { "id": 60325, "portName": "PYEONGTAEK HANG", "country": "KR", "latitude": 37.0, "longitude": 126.8 },
        { "id": 33830, "portName": "LONDONDERRY", "country": "GB", "latitude": 55.0, "longitude": -7.316667 },
        { "id": 35600, "portName": "PORTSMOUTH HARBOUR", "country": "GB", "latitude": 50.8, "longitude": -1.1 },
        { "id": 28480, "portName": "TALLINN", "country": "EE", "latitude": 59.45, "longitude": 24.766667 },
        { "id": 21610, "portName": "HARSTAD", "country": "NO", "latitude": 68.8, "longitude": 16.55 },
        { "id": 54605, "portName": "DAMPIER", "country": "AU", "latitude": -20.633333, "longitude": 116.716667 },
        { "id": 40330, "portName": "VALLETTA HARBORS", "country": "MT", "latitude": 35.9, "longitude": 14.516667 },
        { "id": 58460, "portName": "SAN FERNANDO HARBOR", "country": "PH", "latitude": 16.616667, "longitude": 120.316667 },
        { "id": 38870, "portName": "TOULON", "country": "FR", "latitude": 43.1, "longitude": 5.916667 },
        { "id": 13120, "portName": "MONTEVIDEO", "country": "UY", "latitude": -34.9, "longitude": -56.216667 },
        { "id": 60140, "portName": "QINGDAO GANG", "country": "CN", "latitude": 36.033333, "longitude": 120.266667 },
        { "id": 11035, "portName": "ANDRES (ANDRES LNG TERMINAL)", "country": "DO", "latitude": 18.433333, "longitude": -69.633333 },
        { "id": 8630, "portName": "PORT EVERGLADES", "country": "US", "latitude": 26.1, "longitude": -80.116667 },
        { "id": 44752, "portName": "NEMRUT LIMANI BAY", "country": "TR", "latitude": 38.766667, "longitude": 26.916667 },
        { "id": 1710, "portName": "ARGENTIA", "country": "CA", "latitude": 47.3, "longitude": -53.983333 },
        { "id": 12850, "portName": "VITORIA", "country": "BR", "latitude": -20.316667, "longitude": -40.333333 },
        { "id": 36680, "portName": "LORIENT", "country": "FR", "latitude": 47.75, "longitude": -3.35 },
        { "id": 40530, "portName": "BARI", "country": "IT", "latitude": 41.133333, "longitude": 16.866667 },
        { "id": 12970, "portName": "SANTOS", "country": "BR", "latitude": -23.95, "longitude": -46.3 },
        { "id": 31760, "portName": "SUNDERLAND", "country": "GB", "latitude": 54.916667, "longitude": -1.366667 },
        { "id": 31085, "portName": "EUROPOORT", "country": "NL", "latitude": 51.95, "longitude": 4.116667 },
        { "id": 16340, "portName": "OAKLAND", "country": "US", "latitude": 37.816667, "longitude": -122.333333 },
        { "id": 23090, "portName": "TRONDHEIM", "country": "NO", "latitude": 63.433333, "longitude": 10.4 },
        { "id": 36855, "portName": "MONTOIR", "country": "FR", "latitude": 47.3, "longitude": -2.133333 },
        { "id": 8770, "portName": "MOBILE", "country": "US", "latitude": 30.683333, "longitude": -88.116667 },
        { "id": 28715, "portName": "PORT POLNOCHNY", "country": "PL", "latitude": 54.4, "longitude": 18.716667 },
        { "id": 54520, "portName": "FREMANTLE", "country": "AU", "latitude": -32.05, "longitude": 115.75 },
        { "id": 48276, "portName": "MINA JABAL ALI", "country": "AE", "latitude": 25.016667, "longitude": 55.05 },
        { "id": 60390, "portName": "PUSAN", "country": "KR", "latitude": 35.1, "longitude": 129.033333 },
        { "id": 58320, "portName": "BATANGAS CITY", "country": "PH", "latitude": 13.75, "longitude": 121.05 },
        { "id": 50970, "portName": "JAKARTA", "country": "ID", "latitude": -6.1, "longitude": 106.883333 },
        { "id": 38540, "portName": "TARRAGONA", "country": "ES", "latitude": 41.1, "longitude": 1.233333 },
        { "id": 44750, "portName": "IZMIR", "country": "TR", "latitude": 38.433333, "longitude": 27.133333 },
        { "id": 48327, "portName": "KHALIFA BIN SALMAN", "country": "BH", "latitude": 26.25, "longitude": 50.75 },
        { "id": 11270, "portName": "PLAYA DE GUAYANILLA", "country": "PR", "latitude": 18.016667, "longitude": -66.766667 },
        { "id": 24120, "portName": "HELSINGBORG", "country": "SE", "latitude": 56.05, "longitude": 12.7 },
        { "id": 15770, "portName": "MANZANILLO", "country": "MX", "latitude": 19.05, "longitude": -104.316667 },
        { "id": 44200, "portName": "NOVOROSSIYSK", "country": "RU", "latitude": 44.716667, "longitude": 37.783333 },
        { "id": 15990, "portName": "ENSENADA", "country": "MX", "latitude": 31.866667, "longitude": -116.633333 },
        { "id": 12900, "portName": "DTSE / GEGUA OIL TERMINAL", "country": "BR", "latitude": -22.816667, "longitude": -43.15 },
        { "id": 30800, "portName": "CUXHAVEN", "country": "DE", "latitude": 53.866667, "longitude": 8.716667 },
        { "id": 46110, "portName": "COTONOU", "country": "BJ", "latitude": 6.35, "longitude": 2.433333 },
        { "id": 14500, "portName": "BAHIA DE VALPARAISO", "country": "CL", "latitude": -33.033333, "longitude": -71.616667 },
        { "id": 40790, "portName": "MONFALCONE", "country": "IT", "latitude": 45.783333, "longitude": 13.55 },
        { "id": 37740, "portName": "FERROL", "country": "ES", "latitude": 43.483333, "longitude": -8.233333 },
        { "id": 18150, "portName": "VANCOUVER", "country": "CA", "latitude": 49.283333, "longitude": -123.116667 },
        { "id": 42630, "portName": "ALEXANDROUPOLI", "country": "GR", "latitude": 40.833333, "longitude": 25.883333 },
        { "id": 31765, "portName": "TYNEMOUTH", "country": "GB", "latitude": 55.0, "longitude": -1.4 },
        { "id": 37040, "portName": "ROCHEFORT", "country": "FR", "latitude": 45.933333, "longitude": -0.95 },
        { "id": 37230, "portName": "BAYONNE", "country": "FR", "latitude": 43.5, "longitude": -1.466667 },
        { "id": 51690, "portName": "KOTA KINABALU", "country": "MY", "latitude": 5.983333, "longitude": 116.066667 },
        { "id": 58395, "portName": "SUBIC BAY", "country": "PH", "latitude": 14.8, "longitude": 120.266667 },
        { "id": 53045, "portName": "KASIM TERMINAL", "country": "ID", "latitude": -1.3, "longitude": 131.016667 },
        { "id": 11430, "portName": "ST JOHNS", "country": "AG", "latitude": 17.116667, "longitude": -61.85 },
        { "id": 51220, "portName": "CILACAP", "country": "ID", "latitude": -7.733333, "longitude": 109.0 },
        { "id": 35730, "portName": "DUNKERQUE PORT EST", "country": "FR", "latitude": 51.05, "longitude": 2.383333 },
        { "id": 48263, "portName": "KHAWR FAKKAN", "country": "AE", "latitude": 25.35, "longitude": 56.383333 },
        { "id": 57745, "portName": "FANG-CHENG", "country": "CN", "latitude": 21.75, "longitude": 108.35 },
        { "id": 46770, "portName": "CAPE TOWN", "country": "ZA", "latitude": -33.916667, "longitude": 18.416667 },
        { "id": 34350, "portName": "COBH", "country": "IE", "latitude": 51.85, "longitude": -8.3 },
        { "id": 37470, "portName": "SANTANDER", "country": "ES", "latitude": 43.466667, "longitude": -3.783333 },
        { "id": 46750, "portName": "SALDANHA BAY", "country": "ZA", "latitude": -33.033333, "longitude": 17.966667 },
        { "id": 35732, "portName": "DUNKERQUE PORT OUEST", "country": "FR", "latitude": 51.066667, "longitude": 2.35 },
        { "id": 59710, "portName": "CAGAYAN DE ORO", "country": "PH", "latitude": 8.5, "longitude": 124.666667 },
        { "id": 60380, "portName": "MASAN", "country": "KR", "latitude": 35.183333, "longitude": 128.566667 },
        { "id": 46280, "portName": "DOUALA", "country": "CM", "latitude": 4.05, "longitude": 9.683333 },
        { "id": 11350, "portName": "ROAD HARBOR", "country": "VG", "latitude": 18.416667, "longitude": -64.616667 },
        { "id": 34630, "portName": "BARROW IN FURNESS", "country": "GB", "latitude": 54.116667, "longitude": -3.233333 },
        { "id": 38550, "portName": "BARCELONA", "country": "ES", "latitude": 41.35, "longitude": 2.166667 },
        { "id": 11930, "portName": "BARRANQUILLA", "country": "CO", "latitude": 10.966667, "longitude": -74.766667 },
        { "id": 12855, "portName": "TUBARAO", "country": "BR", "latitude": -20.283333, "longitude": -40.25 },
        { "id": 61170, "portName": "MURORAN KO", "country": "JP", "latitude": 42.35, "longitude": 140.966667 },
        { "id": 27280, "portName": "KARLSBORG", "country": "SE", "latitude": 65.8, "longitude": 23.283333 },
        { "id": 31650, "portName": "IMMINGHAM", "country": "GB", "latitude": 53.633333, "longitude": -0.2 },
        { "id": 31820, "portName": "BLYTH", "country": "GB", "latitude": 55.116667, "longitude": -1.5 },
        { "id": 45753, "portName": "TANGIER-MEDITERRANEAN", "country": "MA", "latitude": 35.9, "longitude": -5.516667 },
        { "id": 20920, "portName": "HAMMERFEST", "country": "NO", "latitude": 70.666667, "longitude": 23.666667 },
        { "id": 28370, "portName": "SANKT-PETERBURG", "country": "RU", "latitude": 59.933333, "longitude": 30.3 },
        { "id": 28230, "portName": "KOTKA", "country": "FI", "latitude": 60.466667, "longitude": 26.966667 },
        { "id": 48361, "portName": "MINA AL AHMADI", "country": "KW", "latitude": 29.066667, "longitude": 48.166667 },
        { "id": 45793, "portName": "CASABLANCA", "country": "MA", "latitude": 33.6, "longitude": -7.616667 },
        { "id": 61662, "portName": "IWAKUNI KO", "country": "JP", "latitude": 34.183333, "longitude": 132.25 },
        { "id": 46440, "portName": "PORT GENTIL", "country": "GA", "latitude": -0.7, "longitude": 8.8 },
        { "id": 45140, "portName": "BUR SAID (PORT SAID)", "country": "EG", "latitude": 31.266667, "longitude": 32.3 },
        { "id": 41010, "portName": "RIJEKA LUKA", "country": "HR", "latitude": 45.333333, "longitude": 14.433333 },
        { "id": 60340, "portName": "MOKPO", "country": "KR", "latitude": 34.783333, "longitude": 126.383333 },
        { "id": 39420, "portName": "RADA DI VADO", "country": "IT", "latitude": 44.266667, "longitude": 8.433333 },
        { "id": 23451, "portName": "KARSTO", "country": "NO", "latitude": 59.266667, "longitude": 5.516667 },
        { "id": 27780, "portName": "RAUMA", "country": "FI", "latitude": 61.133333, "longitude": 21.5 },
        { "id": 60376, "portName": "GWANGYANG HANG", "country": "KR", "latitude": 34.85, "longitude": 127.8 },
        { "id": 48278, "portName": "ABU ZABY", "country": "AE", "latitude": 24.5, "longitude": 54.333333 },
        { "id": 45420, "portName": "LA GOULETTE", "country": "TN", "latitude": 36.8, "longitude": 10.3 },
        { "id": 21310, "portName": "TROMSO", "country": "NO", "latitude": 69.65, "longitude": 18.966667 },
        { "id": 7640, "portName": "NEW YORK CITY", "country": "US", "latitude": 40.7, "longitude": -74.016667 },
        { "id": 10230, "portName": "ANTILLA", "country": "CU", "latitude": 20.833333, "longitude": -75.733333 },
        { "id": 48325, "portName": "MINA SALMAN", "country": "BH", "latitude": 26.2, "longitude": 50.633333 },
        { "id": 61650, "portName": "HIROSHIMA", "country": "JP", "latitude": 34.35, "longitude": 132.466667 },
        { "id": 46430, "portName": "LIBREVILLE", "country": "GA", "latitude": 0.383333, "longitude": 9.45 },
        { "id": 62950, "portName": "MURMANSK", "country": "RU", "latitude": 68.983333, "longitude": 33.05 },
        { "id": 50017, "portName": "JURONG ISLAND", "country": "SG", "latitude": 1.283333, "longitude": 103.733333 },
        { "id": 8530, "portName": "SAVANNAH", "country": "US", "latitude": 32.083333, "longitude": -81.083333 },
        { "id": 7250, "portName": "BOSTON", "country": "US", "latitude": 42.35, "longitude": -71.05 },
        { "id": 59970, "portName": "SHANGHAI", "country": "CN", "latitude": 31.216667, "longitude": 121.5 },
        { "id": 45080, "portName": "HAIFA", "country": "IL", "latitude": 32.816667, "longitude": 35.0 },
        { "id": 14660, "portName": "ANTOFAGASTA", "country": "CL", "latitude": -23.65, "longitude": -70.416667 },
        { "id": 11910, "portName": "CARTAGENA", "country": "CO", "latitude": 10.416667, "longitude": -75.533333 },
        { "id": 39960, "portName": "NAPOLI", "country": "IT", "latitude": 40.85, "longitude": 14.266667 },
        { "id": 45755, "portName": "TANGER", "country": "MA", "latitude": 35.783333, "longitude": -5.816667 },
        { "id": 43760, "portName": "SEVASTOPOL", "country": "UA", "latitude": 44.616667, "longitude": 33.533333 },
        { "id": 61680, "portName": "TOKUYAMA", "country": "JP", "latitude": 34.033333, "longitude": 131.816667 },
        { "id": 57458, "portName": "SI RACHA", "country": "TH", "latitude": 13.166667, "longitude": 100.916667 },
        { "id": 61567, "portName": "HIMEJI", "country": "JP", "latitude": 34.75, "longitude": 134.633333 },
        { "id": 10320, "portName": "PUERTO GUANTANAMO", "country": "CU", "latitude": 19.983333, "longitude": -75.133333 },
        { "id": 45435, "portName": "TAZERKA OIL TERMINAL", "country": "TN", "latitude": 36.6, "longitude": 11.683333 },
        { "id": 53690, "portName": "PORT KEMBLA", "country": "AU", "latitude": -34.483333, "longitude": 150.916667 },
        { "id": 60400, "portName": "ULSAN", "country": "KR", "latitude": 35.45, "longitude": 129.4 },
        { "id": 47100, "portName": "MOMBASA", "country": "KE", "latitude": -4.066667, "longitude": 39.666667 },
        { "id": 36460, "portName": "RADE DE BREST", "country": "FR", "latitude": 48.383333, "longitude": -4.5 },
        { "id": 45490, "portName": "ANNABA", "country": "DZ", "latitude": 36.916667, "longitude": 7.766667 },
        { "id": 45855, "portName": "CONAKRY", "country": "GN", "latitude": 9.516667, "longitude": -13.716667 },
        { "id": 31930, "portName": "LEITH", "country": "GB", "latitude": 55.983333, "longitude": -3.183333 },
        { "id": 57425, "portName": "TAPIS MARINE TERMINAL A", "country": "MY", "latitude": 5.516667, "longitude": 105.016667 },
        { "id": 48040, "portName": "AS SUWAYS", "country": "EG", "latitude": 29.966667, "longitude": 32.55 },
        { "id": 30850, "portName": "BRAKE", "country": "DE", "latitude": 53.333333, "longitude": 8.483333 },
        { "id": 37970, "portName": "AVEIRO", "country": "PT", "latitude": 40.65, "longitude": -8.65 },
        { "id": 53490, "portName": "BRISBANE", "country": "AU", "latitude": -27.466667, "longitude": 153.033333 },
        { "id": 31210, "portName": "VLISSINGEN", "country": "NL", "latitude": 51.45, "longitude": 3.6 },
        { "id": 61545, "portName": "WAKAYAMA-SHIMOTSU KO", "country": "JP", "latitude": 34.2, "longitude": 135.133333 },
        { "id": 14000, "portName": "STANLEY", "country": "FK", "latitude": -51.7, "longitude": -57.833333 },
        { "id": 61415, "portName": "TAGONOURA KO", "country": "JP", "latitude": 35.133333, "longitude": 138.7 },
        { "id": 8470, "portName": "WILMINGTON", "country": "US", "latitude": 34.233333, "longitude": -77.95 },
        { "id": 49240, "portName": "COLOMBO", "country": "LK", "latitude": 6.95, "longitude": 79.85 },
        { "id": 31720, "portName": "TEESPORT", "country": "GB", "latitude": 54.65, "longitude": -1.133333 },
        { "id": 40760, "portName": "PORTO DI LIDO-VENEZIA", "country": "IT", "latitude": 45.416667, "longitude": 12.433333 },
        { "id": 50730, "portName": "BELAWAN", "country": "ID", "latitude": 3.783333, "longitude": 98.683333 },
        { "id": 8210, "portName": "BALTIMORE", "country": "US", "latitude": 39.266667, "longitude": -76.583333 },
        { "id": 58960, "portName": "CEBU", "country": "PH", "latitude": 10.3, "longitude": 123.9 },
        { "id": 27560, "portName": "KOKKOLA", "country": "FI", "latitude": 63.85, "longitude": 23.016667 },
        { "id": 40240, "portName": "SIRACUSA", "country": "IT", "latitude": 37.05, "longitude": 15.283333 },
        { "id": 42530, "portName": "THESSALONIKI", "country": "GR", "latitude": 40.633333, "longitude": 22.933333 },
        { "id": 40480, "portName": "GALLIPOLI", "country": "IT", "latitude": 40.05, "longitude": 17.983333 },
        { "id": 61820, "portName": "FUKUI KO", "country": "JP", "latitude": 36.183333, "longitude": 136.1 },
        { "id": 61568, "portName": "HIGASHI-HARIMA", "country": "JP", "latitude": 34.7, "longitude": 134.833333 },
        { "id": 18950, "portName": "PRINCE RUPERT", "country": "CA", "latitude": 54.316667, "longitude": -130.333333 },
        { "id": 45005, "portName": "BANIYAS", "country": "SY", "latitude": 35.183333, "longitude": 35.95 },
        { "id": 8050, "portName": "WILMINGTON", "country": "US", "latitude": 39.733333, "longitude": -75.55 },
        { "id": 47010, "portName": "DAR ES SALAAM", "country": "TZ", "latitude": -6.816667, "longitude": 39.3 },
        { "id": 38005, "portName": "SINES", "country": "PT", "latitude": 37.95, "longitude": -8.866667 },
        { "id": 1955, "portName": "SEPT ILES", "country": "CA", "latitude": 50.2, "longitude": -66.383333 },
        { "id": 61190, "portName": "HAKODATE KO", "country": "JP", "latitude": 41.783333, "longitude": 140.716667 },
        { "id": 12490, "portName": "BELEM", "country": "BR", "latitude": -1.45, "longitude": -48.5 },
        { "id": 56550, "portName": "APRA HARBOR", "country": "GU", "latitude": 13.45, "longitude": 144.616667 },
        { "id": 49982, "portName": "JOHOR", "country": "MY", "latitude": 1.433333, "longitude": 103.9 },
        { "id": 24020, "portName": "GOTEBORG", "country": "SE", "latitude": 57.7, "longitude": 11.966667 },
        { "id": 49130, "portName": "KOCHI (COCHIN)", "country": "IN", "latitude": 9.966667, "longitude": 76.233333 },
        { "id": 16010, "portName": "SAN DIEGO", "country": "US", "latitude": 32.716667, "longitude": -117.183333 },
        { "id": 44450, "portName": "SAMSUN", "country": "TR", "latitude": 41.3, "longitude": 36.35 },
        { "id": 34990, "portName": "BARRY", "country": "GB", "latitude": 51.4, "longitude": -3.266667 },
        { "id": 62380, "portName": "SASEBO", "country": "JP", "latitude": 33.166667, "longitude": 129.716667 },
        { "id": 48266, "portName": "ZIRKUH PETROLEUM PORT", "country": "AE", "latitude": 25.016667, "longitude": 53.0 },
        { "id": 10530, "portName": "CASILDA", "country": "CU", "latitude": 21.75, "longitude": -79.983333 },
        { "id": 40720, "portName": "PORTO DI CORSINI", "country": "IT", "latitude": 44.5, "longitude": 12.283333 },
        { "id": 100, "portName": "REYKJAVIK", "country": "IS", "latitude": 64.15, "longitude": -21.933333 },
        { "id": 16070, "portName": "LONG BEACH", "country": "US", "latitude": 33.766667, "longitude": -118.183333 },
        { "id": 9370, "portName": "TUXPAN", "country": "MX", "latitude": 20.95, "longitude": -97.4 },
        { "id": 45280, "portName": "BANGHAZI", "country": "LY", "latitude": 32.116667, "longitude": 20.05 },
        { "id": 43100, "portName": "SOUDHA", "country": "GR", "latitude": 35.483333, "longitude": 24.183333 },
        { "id": 61245, "portName": "ISHIKARI BAY NEW PORT", "country": "JP", "latitude": 43.216667, "longitude": 141.283333 },
        { "id": 43560, "portName": "CONSTANTA", "country": "RO", "latitude": 44.166667, "longitude": 28.65 },
        { "id": 56090, "portName": "HILO", "country": "US", "latitude": 19.733333, "longitude": -155.066667 },
        { "id": 45430, "portName": "TUNIS", "country": "TN", "latitude": 36.8, "longitude": 10.25 },
        { "id": 43130, "portName": "IRAKLION", "country": "GR", "latitude": 35.35, "longitude": 25.15 },
        { "id": 31280, "portName": "ZEEBRUGGE", "country": "BE", "latitude": 51.333333, "longitude": 3.2 },
        { "id": 62430, "portName": "HAKATA", "country": "JP", "latitude": 33.6, "longitude": 130.4 },
        { "id": 9080, "portName": "PORT ARTHUR", "country": "US", "latitude": 29.833333, "longitude": -93.966667 },
        { "id": 48845, "portName": "JAWAHARLAL NEHRU PORT (NHAVA SHIVA)", "country": "IN", "latitude": 18.95, "longitude": 72.95 },
        { "id": 54030, "portName": "MELBOURNE", "country": "AU", "latitude": -37.833333, "longitude": 144.966667 },
        { "id": 28890, "portName": "LUBECK", "country": "DE", "latitude": 53.883333, "longitude": 10.7 },
        { "id": 15410, "portName": "BALBOA", "country": "PA", "latitude": 8.95, "longitude": -79.566667 },
        { "id": 36960, "portName": "LES SABLES D' OLONNE", "country": "FR", "latitude": 46.5, "longitude": -1.8 },
        { "id": 49290, "portName": "TRINCOMALEE HARBOR", "country": "LK", "latitude": 8.55, "longitude": 81.216667 },
        { "id": 16080, "portName": "LOS ANGELES", "country": "US", "latitude": 33.75, "longitude": -118.25 },
        { "id": 48297, "portName": "RAS LAFFAN", "country": "QA", "latitude": 25.916667, "longitude": 51.583333 },
        { "id": 61465, "portName": "MIKAWA", "country": "JP", "latitude": 34.716667, "longitude": 137.3 },
        { "id": 34880, "portName": "MILFORD HAVEN", "country": "GB", "latitude": 51.716667, "longitude": -5.033333 },
        { "id": 54220, "portName": "PORT ADELAIDE", "country": "AU", "latitude": -34.85, "longitude": 138.5 },
        { "id": 54760, "portName": "HOBART", "country": "AU", "latitude": -42.883333, "longitude": 147.333333 },
        { "id": 10270, "portName": "BAHIA DE SAGUA DE TANAMO", "country": "CU", "latitude": 20.683333, "longitude": -75.333333 },
        { "id": 48590, "portName": "GWADAR", "country": "PK", "latitude": 25.133333, "longitude": 62.3 },
        { "id": 33490, "portName": "GLASGOW", "country": "GB", "latitude": 55.866667, "longitude": -4.283333 },
        { "id": 15810, "portName": "MAZATLAN", "country": "MX", "latitude": 23.2, "longitude": -106.416667 },
        { "id": 49450, "portName": "CHENNAI (MADRAS)", "country": "IN", "latitude": 13.1, "longitude": 80.3 },
        { "id": 31220, "portName": "TERNEUZEN", "country": "NL", "latitude": 51.35, "longitude": 3.816667 },
        { "id": 39810, "portName": "CIVITAVECCHIA", "country": "IT", "latitude": 42.1, "longitude": 11.783333 },
        { "id": 51587, "portName": "TANJUNG PELEPAS", "country": "MY", "latitude": 1.35, "longitude": 103.55 },
        { "id": 51130, "portName": "SURABAYA", "country": "ID", "latitude": -7.2, "longitude": 112.733333 },
        { "id": 30285, "portName": "STUDSTRUP", "country": "DK", "latitude": 56.25, "longitude": 10.35 },
        { "id": 28300, "portName": "VYBORG", "country": "RU", "latitude": 60.716667, "longitude": 28.75 },
        { "id": 43565, "portName": "DANUBE-BLACK SEA CANAL", "country": "RO", "latitude": 44.266667, "longitude": 28.183333 },
        { "id": 42230, "portName": "PIRAIEVS", "country": "GR", "latitude": 37.933333, "longitude": 23.65 },
        { "id": 49480, "portName": "VISHAKHAPATNAM", "country": "IN", "latitude": 17.683333, "longitude": 83.3 },
        { "id": 8580, "portName": "JACKSONVILLE", "country": "US", "latitude": 30.316667, "longitude": -81.65 },
        { "id": 28930, "portName": "KIEL", "country": "DE", "latitude": 54.316667, "longitude": 10.133333 },
        { "id": 32230, "portName": "PETERHEAD", "country": "GB", "latitude": 57.5, "longitude": -1.783333 },
        { "id": 23480, "portName": "STAVANGER", "country": "NO", "latitude": 58.966667, "longitude": 5.75 },
        { "id": 61640, "portName": "KURE", "country": "JP", "latitude": 34.233333, "longitude": 132.55 },
        { "id": 55230, "portName": "MANUKAU HARBOR", "country": "NZ", "latitude": -36.933333, "longitude": 174.783333 },
        { "id": 38630, "portName": "PALMA DE MALLORCA", "country": "ES", "latitude": 39.566667, "longitude": 2.633333 },
        { "id": 62120, "portName": "WAKAMATSU KO", "country": "JP", "latitude": 33.9, "longitude": 130.816667 },
        { "id": 33390, "portName": "GREENOCK", "country": "GB", "latitude": 55.95, "longitude": -4.75 },
        { "id": 45330, "portName": "MINA TARABULUS (TRIPOLI)", "country": "LY", "latitude": 32.9, "longitude": 13.183333 },
        { "id": 28860, "portName": "ROSTOCK", "country": "DE", "latitude": 54.1, "longitude": 12.133333 },
        { "id": 25910, "portName": "SUNDSVALL", "country": "SE", "latitude": 62.383333, "longitude": 17.35 },
        { "id": 50000, "portName": "KEPPEL - (EAST SINGAPORE)", "country": "SG", "latitude": 1.283333, "longitude": 103.85 },
        { "id": 8860, "portName": "NEW ORLEANS", "country": "US", "latitude": 29.95, "longitude": -90.05 },
        { "id": 35070, "portName": "BRISTOL", "country": "GB", "latitude": 51.466667, "longitude": -2.633333 },
        { "id": 11960, "portName": "MARACAIBO", "country": "VE", "latitude": 10.633333, "longitude": -71.6 },
        { "id": 10880, "portName": "PORT AU PRINCE", "country": "HT", "latitude": 18.55, "longitude": -72.35 },
        { "id": 35580, "portName": "SOUTHAMPTON", "country": "GB", "latitude": 50.9, "longitude": -1.4 },
        { "id": 50010, "portName": "PULAU BUKOM", "country": "SG", "latitude": 1.233333, "longitude": 103.766667 },
        { "id": 50922, "portName": "MERAK MAS TERMINAL", "country": "ID", "latitude": -5.916667, "longitude": 105.983333 },
        { "id": 6545, "portName": "CANAPORT (ST. JOHN)", "country": "CA", "latitude": 45.2, "longitude": -65.983333 },
        { "id": 11720, "portName": "PORT OF SPAIN", "country": "TT", "latitude": 10.65, "longitude": -61.516667 },
        { "id": 27520, "portName": "OULU", "country": "FI", "latitude": 65.016667, "longitude": 25.4 },
        { "id": 28740, "portName": "GDYNIA", "country": "PL", "latitude": 54.533333, "longitude": 18.55 },
        { "id": 54350, "portName": "PORT LINCOLN", "country": "AU", "latitude": -34.716667, "longitude": 135.85 },
        { "id": 62220, "portName": "KAGOSHIMA KO", "country": "JP", "latitude": 31.583333, "longitude": 130.566667 },
        { "id": 38440, "portName": "ALICANTE", "country": "ES", "latitude": 38.333333, "longitude": -0.483333 },
        { "id": 57862, "portName": "CHAOZHOU", "country": "CN", "latitude": 23.616667, "longitude": 117.083333 },
        { "id": 27680, "portName": "KRISTINESTAD", "country": "FI", "latitude": 62.283333, "longitude": 21.4 },
        { "id": 37400, "portName": "PUERTO DE BILBAO", "country": "ES", "latitude": 43.35, "longitude": -3.05 },
        { "id": 62800, "portName": "ARKHANGELS'K", "country": "RU", "latitude": 64.533333, "longitude": 40.533333 },
        { "id": 11820, "portName": "BULLEN BAAI", "country": "CW", "latitude": 12.183333, "longitude": -69.033333 },
        { "id": 37290, "portName": "PUERTO DE PASAJES", "country": "ES", "latitude": 43.333333, "longitude": -1.933333 },
        { "id": 7780, "portName": "JERSEY CITY", "country": "US", "latitude": 40.716667, "longitude": -74.033333 },
        { "id": 9360, "portName": "TAMPICO", "country": "MX", "latitude": 22.216667, "longitude": -97.866667 },
        { "id": 15760, "portName": "ACAPULCO", "country": "MX", "latitude": 16.85, "longitude": -99.933333 },
        { "id": 53610, "portName": "NEWCASTLE", "country": "AU", "latitude": -32.916667, "longitude": 151.783333 },
        { "id": 60200, "portName": "QINHUANGDAO", "country": "CN", "latitude": 39.933333, "longitude": 119.616667 },
        { "id": 27670, "portName": "KASKINEN", "country": "FI", "latitude": 62.383333, "longitude": 21.233333 },
        { "id": 43645, "portName": "ILLICHIVSK", "country": "UA", "latitude": 46.3, "longitude": 30.65 },
        { "id": 9990, "portName": "NASSAU", "country": "BS", "latitude": 25.083333, "longitude": -77.35 },
        { "id": 45570, "portName": "ALGER", "country": "DZ", "latitude": 36.766667, "longitude": 3.066667 },
        { "id": 12730, "portName": "RECIFE", "country": "BR", "latitude": -8.05, "longitude": -34.866667 },
        { "id": 46670, "portName": "GEORGETOWN", "country": "SH", "latitude": -7.933333, "longitude": -14.416667 },
        { "id": 7150, "portName": "PORTLAND", "country": "US", "latitude": 43.666667, "longitude": -70.25 },
        { "id": 24030, "portName": "VARBERG", "country": "SE", "latitude": 57.116667, "longitude": 12.25 },
        { "id": 35370, "portName": "PLYMOUTH", "country": "GB", "latitude": 50.366667, "longitude": -4.15 },
        { "id": 38170, "portName": "LAS PALMAS", "country": "ES", "latitude": 28.15, "longitude": -15.416667 },
        { "id": 35010, "portName": "CARDIFF", "country": "GB", "latitude": 51.45, "longitude": -3.166667 },
        { "id": 30780, "portName": "HAMBURG", "country": "DE", "latitude": 53.55, "longitude": 9.933333 },
        { "id": 46850, "portName": "DURBAN", "country": "ZA", "latitude": -29.866667, "longitude": 31.066667 },
        { "id": 61870, "portName": "NIIGATA KO", "country": "JP", "latitude": 37.916667, "longitude": 139.05 },
        { "id": 30950, "portName": "EMDEN", "country": "DE", "latitude": 53.333333, "longitude": 7.183333 },
        { "id": 7790, "portName": "BAYONNE", "country": "US", "latitude": 40.683333, "longitude": -74.1 },
        { "id": 37900, "portName": "VIGO", "country": "ES", "latitude": 42.233333, "longitude": -8.716667 },
        { "id": 46040, "portName": "TAKORADI", "country": "GH", "latitude": 4.883333, "longitude": -1.733333 },
        { "id": 7770, "portName": "HOBOKEN", "country": "US", "latitude": 40.75, "longitude": -74.016667 },
        { "id": 12185, "portName": "PUERTO LA CRUZ", "country": "VE", "latitude": 10.25, "longitude": -64.633333 },
        { "id": 31140, "portName": "ROTTERDAM", "country": "NL", "latitude": 51.9, "longitude": 4.483333 },
        { "id": 61547, "portName": "SAKAI-SENBOKU", "country": "JP", "latitude": 34.55, "longitude": 135.433333 },
        { "id": 31470, "portName": "LONDON", "country": "GB", "latitude": 51.5, "longitude": -0.083333 },
        { "id": 10150, "portName": "NUEVITAS BAY", "country": "CU", "latitude": 21.55, "longitude": -77.266667 },
        { "id": 61690, "portName": "UBE KO", "country": "JP", "latitude": 33.933333, "longitude": 131.233333 },
        { "id": 31150, "portName": "DORDRECHT", "country": "NL", "latitude": 51.816667, "longitude": 4.65 },
        { "id": 28190, "portName": "HELSINKI", "country": "FI", "latitude": 60.166667, "longitude": 24.966667 },
        { "id": 37010, "portName": "LA ROCHELLE", "country": "FR", "latitude": 46.15, "longitude": -1.15 },
        { "id": 37990, "portName": "LISBOA", "country": "PT", "latitude": 38.7, "longitude": -9.166667 },
        { "id": 38480, "portName": "VALENCIA", "country": "ES", "latitude": 39.45, "longitude": -0.316667 },
        { "id": 23160, "portName": "BERGEN", "country": "NO", "latitude": 60.4, "longitude": 5.316667 },
        { "id": 38130, "portName": "FUNCHAL", "country": "PT", "latitude": 32.633333, "longitude": -16.916667 },
        { "id": 35950, "portName": "RADE DE CHERBOURG", "country": "FR", "latitude": 49.65, "longitude": -1.633333 },
        { "id": 38330, "portName": "EUROPA POINT", "country": "GI", "latitude": 36.133333, "longitude": -5.35 },
        { "id": 57777, "portName": "SHEKOU", "country": "CN", "latitude": 22.466667, "longitude": 113.866667 },
        { "id": 24210, "portName": "MALMO", "country": "SE", "latitude": 55.616667, "longitude": 13.0 },
        { "id": 57920, "portName": "KAO-HSIUNG", "country": "TW", "latitude": 22.616667, "longitude": 120.25 },
        { "id": 62360, "portName": "NAGASAKI", "country": "JP", "latitude": 32.716667, "longitude": 129.85 },
        { "id": 24040, "portName": "FALKENBERG", "country": "SE", "latitude": 56.9, "longitude": 12.5 },
        { "id": 40470, "portName": "TARANTO", "country": "IT", "latitude": 40.466667, "longitude": 17.2 },
        { "id": 34130, "portName": "FOYNES", "country": "IE", "latitude": 52.616667, "longitude": -9.116667 },
        { "id": 31660, "portName": "KINGSTON UPON HULL", "country": "GB", "latitude": 53.75, "longitude": -0.283333 },
        { "id": 11040, "portName": "SANTO DOMINGO", "country": "DO", "latitude": 18.466667, "longitude": -69.883333 },
        { "id": 28425, "portName": "SILLAMAE", "country": "EE", "latitude": 59.4, "longitude": 27.05 },
        { "id": 39215, "portName": "SARROCH OIL TERMINAL", "country": "IT", "latitude": 39.083333, "longitude": 9.033333 },
        { "id": 57775, "portName": "LON SHUI TERMINAL", "country": "CN", "latitude": 20.833333, "longitude": 115.683333 },
        { "id": 35015, "portName": "NEWPORT", "country": "GB", "latitude": 51.55, "longitude": -2.983333 },
        { "id": 8670, "portName": "TAMPA", "country": "US", "latitude": 27.916667, "longitude": -82.45 },
        { "id": 32600, "portName": "LERWICK", "country": "GB", "latitude": 60.15, "longitude": -1.15 },
        { "id": 46000, "portName": "ABIDJAN", "country": "CI", "latitude": 5.25, "longitude": -4.016667 },
        { "id": 61970, "portName": "KOCHI KO", "country": "JP", "latitude": 33.5, "longitude": 133.566667 },
        { "id": 23138, "portName": "MONGSTAD", "country": "NO", "latitude": 60.816667, "longitude": 5.033333 },
        { "id": 23750, "portName": "HORTEN", "country": "NO", "latitude": 59.416667, "longitude": 10.5 },
        { "id": 57910, "portName": "HUA-LIEN KANG", "country": "TW", "latitude": 23.983333, "longitude": 121.6 },
        { "id": 10770, "portName": "KINGSTON", "country": "JM", "latitude": 17.966667, "longitude": -76.783333 },
        { "id": 48357, "portName": "MINA ASH SHUAYBAH", "country": "KW", "latitude": 29.033333, "longitude": 48.166667 },
        { "id": 38220, "portName": "HUELVA", "country": "ES", "latitude": 37.25, "longitude": -6.95 },
        { "id": 61790, "portName": "MIYAZU", "country": "JP", "latitude": 35.533333, "longitude": 135.2 },
        { "id": 48840, "portName": "MUMBAI (BOMBAY)", "country": "IN", "latitude": 18.966667, "longitude": 72.866667 },
        { "id": 41720, "portName": "KERKIRA", "country": "GR", "latitude": 39.616667, "longitude": 19.933333 },
        { "id": 16300, "portName": "SAN FRANCISCO", "country": "US", "latitude": 37.816667, "longitude": -122.416667 },
        { "id": 58370, "portName": "MANILA", "country": "PH", "latitude": 14.583333, "longitude": 120.966667 },
        { "id": 57810, "portName": "MACAU", "country": "MO", "latitude": 22.183333, "longitude": 113.566667 },
        { "id": 33770, "portName": "BELFAST", "country": "GB", "latitude": 54.616667, "longitude": -5.9 },
        { "id": 48470, "portName": "BUSHEHR", "country": "IR", "latitude": 28.983333, "longitude": 50.833333 },
        { "id": 35290, "portName": "FALMOUTH HARBOUR", "country": "GB", "latitude": 50.15, "longitude": -5.066667 },
        { "id": 60150, "portName": "WEIHAI", "country": "CN", "latitude": 37.5, "longitude": 122.1 },
        { "id": 25190, "portName": "NORRKOPING", "country": "SE", "latitude": 58.583333, "longitude": 16.2 },
        { "id": 45165, "portName": "AL ISKANDARIYH (ALEXANDRIA)", "country": "EG", "latitude": 31.166667, "longitude": 29.833333 },
        { "id": 28660, "portName": "KLAIPEDA", "country": "LT", "latitude": 55.716667, "longitude": 21.116667 },
        { "id": 34360, "portName": "CORK", "country": "IE", "latitude": 51.85, "longitude": -8.266667 },
        { "id": 10540, "portName": "CIENFUEGOS", "country": "CU", "latitude": 22.15, "longitude": -80.45 },
        { "id": 28640, "portName": "LIEPAJA", "country": "LV", "latitude": 56.516667, "longitude": 21.016667 },
        { "id": 13080, "portName": "PORTO ALEGRE", "country": "BR", "latitude": -30.033333, "longitude": -51.233333 },
        { "id": 46820, "portName": "PORT ELIZABETH", "country": "ZA", "latitude": -33.966667, "longitude": 25.616667 },
        { "id": 11170, "portName": "ENSENADA HONDA", "country": "PR", "latitude": 18.233333, "longitude": -65.633333 },
        { "id": 39470, "portName": "GENOVA", "country": "IT", "latitude": 44.4, "longitude": 8.933333 },
        { "id": 43510, "portName": "VARNA", "country": "BG", "latitude": 43.183333, "longitude": 27.966667 },
        { "id": 61560, "portName": "KOBE", "country": "JP", "latitude": 34.65, "longitude": 135.183333 },
        { "id": 43420, "portName": "ISTANBUL", "country": "TR", "latitude": 41.016667, "longitude": 28.966667 },
        { "id": 60610, "portName": "VLADIVOSTOK", "country": "RU", "latitude": 43.116667, "longitude": 131.9 },
        { "id": 27750, "portName": "MANTYLUOTO", "country": "FI", "latitude": 61.6, "longitude": 21.483333 },
        { "id": 11450, "portName": "POINTE A PITRE", "country": "GP", "latitude": 16.233333, "longitude": -61.533333 },
        { "id": 29230, "portName": "KOBENHAVN", "country": "DK", "latitude": 55.7, "longitude": 12.616667 },
        { "id": 61380, "portName": "TOKYO KO", "country": "JP", "latitude": 35.666667, "longitude": 139.75 },
        { "id": 62140, "portName": "MOJI KO", "country": "JP", "latitude": 33.95, "longitude": 130.966667 },
        { "id": 25380, "portName": "STOCKHOLM", "country": "SE", "latitude": 59.333333, "longitude": 18.05 },
        { "id": 46090, "portName": "LOME", "country": "TG", "latitude": 6.133333, "longitude": 1.283333 },
        { "id": 48600, "portName": "KARACHI", "country": "PK", "latitude": 24.783333, "longitude": 66.983333 },
        { "id": 50785, "portName": "DUMAI", "country": "ID", "latitude": 1.683333, "longitude": 101.45 },
        { "id": 8300, "portName": "NEWPORT NEWS", "country": "US", "latitude": 36.966667, "longitude": -76.433333 },
        { "id": 38370, "portName": "MALAGA", "country": "ES", "latitude": 36.716667, "longitude": -4.416667 },
        { "id": 45820, "portName": "DAKAR", "country": "SN", "latitude": 14.683333, "longitude": -17.433333 },
        { "id": 55150, "portName": "WELLINGTON", "country": "NZ", "latitude": -41.283333, "longitude": 174.783333 },
        { "id": 57840, "portName": "HONG KONG", "country": "HK", "latitude": 22.266667, "longitude": 114.2 },
        { "id": 54940, "portName": "PORT DALRYMPLE", "country": "AU", "latitude": -41.133333, "longitude": 146.833333 },
        { "id": 60190, "portName": "TIANJIN XIN GANG", "country": "CN", "latitude": 38.966667, "longitude": 117.833333 },
        { "id": 48055, "portName": "AIN SUKHNA TERMINAL", "country": "EG", "latitude": 29.583333, "longitude": 32.366667 },
        { "id": 46855, "portName": "RICHARDS BAY", "country": "ZA", "latitude": -28.816667, "longitude": 32.083333 },
        { "id": 48320, "portName": "SITRAH", "country": "BH", "latitude": 26.166667, "longitude": 50.666667 },
        { "id": 36850, "portName": "ST NAZAIRE", "country": "FR", "latitude": 47.283333, "longitude": -2.2 },
        { "id": 31640, "portName": "GRIMSBY", "country": "GB", "latitude": 53.583333, "longitude": -0.066667 },
        { "id": 45030, "portName": "BAYRUT", "country": "LB", "latitude": 33.9, "longitude": 35.5 },
        { "id": 28610, "portName": "RIGA", "country": "LV", "latitude": 56.95, "longitude": 24.1 },
        { "id": 57890, "portName": "CHI-LUNG", "country": "TW", "latitude": 25.133333, "longitude": 121.766667 },
        { "id": 13020, "portName": "ITAJAI", "country": "BR", "latitude": -26.916667, "longitude": -48.633333 },
        { "id": 45812, "portName": "NOUADHIBOU", "country": "MR", "latitude": 20.916667, "longitude": -17.05 },
        { "id": 57446, "portName": "SATTAHIP", "country": "TH", "latitude": 12.616667, "longitude": 100.916667 },
        { "id": 12370, "portName": "GEORGETOWN", "country": "GY", "latitude": 6.833333, "longitude": -58.166667 },
        { "id": 14950, "portName": "PUERTO DEL CALLAO", "country": "PE", "latitude": -12.05, "longitude": -77.15 },
        { "id": 44860, "portName": "MERSIN", "country": "TR", "latitude": 36.8, "longitude": 34.633333 },
        { "id": 105, "portName": "GRUNDARTANGI", "country": "IS", "latitude": 64.266667, "longitude": -22.0 },
        { "id": 45802, "portName": "AGADIR", "country": "MA", "latitude": 30.433333, "longitude": -9.633333 },
        { "id": 7420, "portName": "PROVIDENCE", "country": "US", "latitude": 41.8, "longitude": -71.4 },
        { "id": 7630, "portName": "BROOKLYN", "country": "US", "latitude": 40.666667, "longitude": -74.016667 },
        { "id": 6340, "portName": "HALIFAX", "country": "CA", "latitude": 44.65, "longitude": -63.583333 },
        { "id": 60250, "portName": "DALIAN", "country": "CN", "latitude": 38.916667, "longitude": 121.666667 },
        { "id": 44540, "portName": "HAYDARPASA", "country": "TR", "latitude": 41.0, "longitude": 29.016667 },
        { "id": 9950, "portName": "IRELAND ISLAND", "country": "BM", "latitude": 32.316667, "longitude": -64.833333 },
        { "id": 38160, "portName": "SANTA CRUZ DE TENERIFE", "country": "ES", "latitude": 28.466667, "longitude": -16.233333 },
        { "id": 27220, "portName": "LULEA", "country": "SE", "latitude": 65.583333, "longitude": 22.166667 },
        { "id": 45151, "portName": "DAMIETTA", "country": "EG", "latitude": 31.483333, "longitude": 31.75 },
        { "id": 34480, "portName": "DUBLIN", "country": "IE", "latitude": 53.35, "longitude": -6.25 },
        { "id": 7810, "portName": "NEWARK", "country": "US", "latitude": 40.7, "longitude": -74.15 },
        { "id": 11810, "portName": "WILLEMSTAD", "country": "CW", "latitude": 12.116667, "longitude": -68.933333 },
        { "id": 13770, "portName": "LA PLATA", "country": "AR", "latitude": -34.833333, "longitude": -57.883333 },
        { "id": 42340, "portName": "ORMOS ALIVERIOU", "country": "GR", "latitude": 38.383333, "longitude": 24.05 },
        { "id": 48190, "portName": "ADEN", "country": "YE", "latitude": 12.783333, "longitude": 44.95 },
        { "id": 9865, "portName": "PUERTO COLON", "country": "PA", "latitude": 9.366667, "longitude": -79.883333 },
        { "id": 28823, "portName": "SZCZECIN", "country": "PL", "latitude": 53.416667, "longitude": 14.55 },
        { "id": 12120, "portName": "PUERTO CABELLO", "country": "VE", "latitude": 10.483333, "longitude": -68.0 },
        { "id": 49930, "portName": "PORT KLANG", "country": "MY", "latitude": 3.0, "longitude": 101.4 },
        { "id": 30810, "portName": "BREMERHAVEN", "country": "DE", "latitude": 53.533333, "longitude": 8.583333 },
        { "id": 9300, "portName": "CORPUS CHRISTI", "country": "US", "latitude": 27.816667, "longitude": -97.4 },
        { "id": 35450, "portName": "PORTLAND HARBOUR", "country": "GB", "latitude": 50.583333, "longitude": -2.433333 },
        { "id": 7840, "portName": "TOMPKINSVILLE SI", "country": "US", "latitude": 40.633333, "longitude": -74.066667 },
        { "id": 6000, "portName": "SYDNEY", "country": "CA", "latitude": 46.15, "longitude": -60.2 },
        { "id": 45025, "portName": "TARABULUS", "country": "LB", "latitude": 34.45, "longitude": 35.833333 },
        { "id": 32220, "portName": "ABERDEEN", "country": "GB", "latitude": 57.15, "longitude": -2.083333 },
        { "id": 8280, "portName": "NORFOLK", "country": "US", "latitude": 36.85, "longitude": -76.3 },
        { "id": 9380, "portName": "VERACRUZ", "country": "MX", "latitude": 19.2, "longitude": -96.133333 },
        { "id": 11260, "portName": "PLAYA DE PONCE", "country": "PR", "latitude": 17.983333, "longitude": -66.616667 },
        { "id": 12770, "portName": "PORT DE SALVADOR", "country": "BR", "latitude": -12.966667, "longitude": -38.516667 },
        { "id": 30260, "portName": "ARHUS", "country": "DK", "latitude": 56.15, "longitude": 10.216667 },
        { "id": 55060, "portName": "AUCKLAND", "country": "NZ", "latitude": -36.85, "longitude": 174.766667 },
        { "id": 40820, "portName": "TRIESTE", "country": "IT", "latitude": 45.65, "longitude": 13.766667 },
        { "id": 40500, "portName": "BRINDISI", "country": "IT", "latitude": 40.65, "longitude": 17.983333 },
        { "id": 49960, "portName": "PORT DICKSON", "country": "MY", "latitude": 2.533333, "longitude": 101.783333 },
        { "id": 38290, "portName": "CADIZ", "country": "ES", "latitude": 36.533333, "longitude": -6.3 },
        { "id": 9860, "portName": "PUERTO CRISTOBAL", "country": "PA", "latitude": 9.35, "longitude": -79.916667 },
        { "id": 52320, "portName": "UJUNG PANDANG", "country": "ID", "latitude": -5.133333, "longitude": 119.4 },
        { "id": 61230, "portName": "OTARU KO", "country": "JP", "latitude": 43.2, "longitude": 141.016667 },
        { "id": 56280, "portName": "HONOLULU", "country": "US", "latitude": 21.3, "longitude": -157.866667 },
        { "id": 47944, "portName": "BESHAYER OIL TERMINAL", "country": "SD", "latitude": 19.4, "longitude": 37.316667 },
        { "id": 48520, "portName": "BANDAR ABBAS", "country": "IR", "latitude": 27.15, "longitude": 56.2 },
        { "id": 61160, "portName": "KUSHIRO KO", "country": "JP", "latitude": 42.983333, "longitude": 144.366667 },
        { "id": 9150, "portName": "GALVESTON", "country": "US", "latitude": 29.316667, "longitude": -94.783333 },
        { "id": 49850, "portName": "PULAU PINANG", "country": "MY", "latitude": 5.416667, "longitude": 100.35 },
        { "id": 54670, "portName": "DARWIN", "country": "AU", "latitude": -12.466667, "longitude": 130.85 },
        { "id": 28690, "portName": "KALININGRAD", "country": "RU", "latitude": 54.7, "longitude": 20.483333 },
        { "id": 47160, "portName": "MUQDISHO", "country": "SO", "latitude": 2.033333, "longitude": 45.35 },
        { "id": 46200, "portName": "PENNINGTON OIL TERMINAL", "country": "NG", "latitude": 4.25, "longitude": 5.616667 },
        { "id": 16740, "portName": "EMPIRE", "country": "US", "latitude": 43.4, "longitude": -124.283333 },
        { "id": 57870, "portName": "XIAMEN", "country": "CN", "latitude": 24.45, "longitude": 118.066667 },
        { "id": 12920, "portName": "RIO DE JANEIRO", "country": "BR", "latitude": -22.9, "longitude": -43.166667 },
        { "id": 50910, "portName": "FLYING FISH COVE", "country": "CX", "latitude": -10.416667, "longitude": 105.7 },
        { "id": 37000, "portName": "LA PALLICE", "country": "FR", "latitude": 46.166667, "longitude": -1.216667 },
        { "id": 54050, "portName": "GEELONG", "country": "AU", "latitude": -38.15, "longitude": 144.366667 },
        { "id": 17730, "portName": "SEATTLE", "country": "US", "latitude": 47.6, "longitude": -122.333333 },
        { "id": 31060, "portName": "AMSTERDAM", "country": "NL", "latitude": 52.366667, "longitude": 4.9 },
        { "id": 8980, "portName": "LOOP TERMINAL", "country": "US", "latitude": 28.883333, "longitude": -90.016667 },
        { "id": 57900, "portName": "SU-AO", "country": "TW", "latitude": 24.6, "longitude": 121.866667 },
        { "id": 28710, "portName": "GDANSK", "country": "PL", "latitude": 54.35, "longitude": 18.666667 },
        { "id": 41320, "portName": "SPLIT", "country": "HR", "latitude": 43.5, "longitude": 16.433333 },
        { "id": 6550, "portName": "ST JOHN", "country": "CA", "latitude": 45.266667, "longitude": -66.05 },
        { "id": 47940, "portName": "PORT SUDAN", "country": "SD", "latitude": 19.6, "longitude": 37.233333 },
        { "id": 27740, "portName": "PORI", "country": "FI", "latitude": 61.483333, "longitude": 21.8 },
        { "id": 13760, "portName": "BUENOS AIRES", "country": "AR", "latitude": -34.6, "longitude": -58.366667 },
        { "id": 13060, "portName": "RIO GRANDE", "country": "BR", "latitude": -32.05, "longitude": -52.083333 },
        { "id": 60320, "portName": "INCHON", "country": "KR", "latitude": 37.466667, "longitude": 126.616667 },
        { "id": 32490, "portName": "LYNESS", "country": "GB", "latitude": 58.833333, "longitude": -3.2 },
        { "id": 42470, "portName": "VOLOS", "country": "GR", "latitude": 39.366667, "longitude": 22.95 },
        { "id": 34690, "portName": "LIVERPOOL", "country": "GB", "latitude": 53.416667, "longitude": -3.0 },
        { "id": 46870, "portName": "MAPUTO", "country": "MZ", "latitude": -25.966667, "longitude": 32.583333 },
        { "id": 46890, "portName": "BEIRA", "country": "MZ", "latitude": -19.833333, "longitude": 34.833333 },
        { "id": 11550, "portName": "FORT DE FRANCE", "country": "MQ", "latitude": 14.6, "longitude": -61.066667 },
        { "id": 45640, "portName": "ORAN", "country": "DZ", "latitude": 35.7, "longitude": -0.633333 },
        { "id": 32170, "portName": "DUNDEE", "country": "GB", "latitude": 56.466667, "longitude": -2.95 },
        { "id": 46205, "portName": "BONNY", "country": "NG", "latitude": 4.45, "longitude": 7.166667 },
        { "id": 53650, "portName": "SYDNEY", "country": "AU", "latitude": -33.866667, "longitude": 151.2 },
        { "id": 10080, "portName": "CABANAS", "country": "CU", "latitude": 23.0, "longitude": -82.966667 },
        { "id": 23990, "portName": "UDDEVALLA", "country": "SE", "latitude": 58.35, "longitude": 11.916667 },
        { "id": 49030, "portName": "NEW MANGALORE", "country": "IN", "latitude": 12.916667, "longitude": 74.816667 },
        { "id": 57485, "portName": "KAMPONG SAOM", "country": "KH", "latitude": 10.633333, "longitude": 103.5 },
        { "id": 48015, "portName": "RAS SHUKHIER", "country": "EG", "latitude": 28.133333, "longitude": 33.283333 },
        { "id": 35760, "portName": "BOULOGNE-SUR-MER", "country": "FR", "latitude": 50.733333, "longitude": 1.6 },
        { "id": 43650, "portName": "ODESA", "country": "UA", "latitude": 46.483333, "longitude": 30.733333 },
        { "id": 59910, "portName": "WENZHOU", "country": "CN", "latitude": 28.016667, "longitude": 120.65 },
        { "id": 37190, "portName": "BORDEAUX", "country": "FR", "latitude": 44.866667, "longitude": -0.566667 },
        { "id": 10370, "portName": "SANTIAGO DE CUBA", "country": "CU", "latitude": 20.016667, "longitude": -75.833333 },
        { "id": 10100, "portName": "BAHAI DE LA HABANA", "country": "CU", "latitude": 23.133333, "longitude": -82.366667 },
        { "id": 48155, "portName": "RAS ISA MARINE TERMINAL", "country": "YE", "latitude": 15.133333, "longitude": 42.6 },
        { "id": 48340, "portName": "RAS  TANNURAH", "country": "SA", "latitude": 26.633333, "longitude": 50.166667 },
        { "id": 61550, "portName": "OSAKA", "country": "JP", "latitude": 34.65, "longitude": 135.433333 },
        { "id": 48282, "portName": "JABAL AZ ZANNAH/RUWAYS", "country": "AE", "latitude": 24.2, "longitude": 52.7 },
        { "id": 40110, "portName": "MILAZZO", "country": "IT", "latitude": 38.216667, "longitude": 15.25 },
        { "id": 39520, "portName": "LA SPEZIA", "country": "IT", "latitude": 44.1, "longitude": 9.833333 },
        { "id": 49590, "portName": "CHITTAGONG", "country": "BD", "latitude": 22.316667, "longitude": 91.816667 },
        { "id": 24370, "portName": "KARLSHAMN", "country": "SE", "latitude": 56.166667, "longitude": 14.866667 }
    ],
    "nextPageToken": null
}
